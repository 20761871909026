import React from 'react';
import Header from '../components/Header';
import { FaTelegram } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import '../styles/ContactPage.css';

const ContactPage = () => {
  return (
    <div className="page-container">
      <Header />
      
      <section className="contact-hero-section">
        <h1>Get in Touch</h1>
        <p>We're here to help with any questions you might have</p>
      </section>

      <section className="contact-methods">
        <div className="contact-card">
          <div className="contact-icon">
            <FaTelegram />
          </div>
          <h3>Telegram</h3>
          <p>For fastest response, reach us on Telegram</p>
          <a 
            href="https://t.me/deathbydesign" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="contact-button"
          >
            Message on Telegram
          </a>
        </div>

        <div className="contact-card">
          <div className="contact-icon">
            <MdEmail />
          </div>
          <h3>Email</h3>
          <p>Send us an email for general inquiries</p>
          <a 
            href="mailto:support@deathby.design" 
            className="contact-button"
          >
            Send Email
          </a>
        </div>
      </section>

      <footer className="landing-footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Products</h3>
            <ul>
              <li><Link to="/lte-proxies">LTE Proxies</Link></li>
              <li><Link to="/residential-proxies">Residential Proxies</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Company</h3>
            <ul>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Support</h3>
            <ul>
              <li><Link to="/faq">FAQ</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} DeathBy.Design. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default ContactPage; 