import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import '../styles/FAQPage.css';

const FAQPage = () => {
  const faqs = [
    {
      question: "What payment methods do you accept?",
      answer: "We accept all major cryptocurrencies for secure and anonymous transactions."
    },
    {
      question: "Can I upgrade my plan later?",
      answer: "Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle."
    },
    {
      question: "Do you offer refunds?",
      answer: "No, we do not offer refunds at this moment due to the nature of our digital services."
    },
    {
      question: "How do I get started?",
      answer: "Simply create an account, choose your preferred proxy plan, and make a payment. You'll receive access credentials immediately after confirmation."
    },
    {
      question: "Are the proxies shared or private?",
      answer: "We currently offer only shared proxy options. Check our pricing page for detailed information about each plan."
    },
    {
      question: "What's your uptime guarantee?",
      answer: "We maintain a 99.9% uptime guarantee for all our proxy services, ensuring reliable connectivity."
    }
  ];

  return (
    <div className="page-container">
      <Header />
      
      <section className="faq-hero-section">
        <h1>Frequently Asked Questions</h1>
        <p>Find answers to common questions about our services</p>
      </section>

      <section className="faq-content">
        <div className="faq-grid">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <h3>{faq.question}</h3>
              <p>{faq.answer}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="faq-cta">
        <h2>Still have questions?</h2>
        <p>We're here to help you with any other questions you might have</p>
        <div className="cta-buttons">
          <Link to="/contact" className="primary-button">Contact Support</Link>
        </div>
      </section>

      <footer className="landing-footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Products</h3>
            <ul>
              <li><Link to="/lte-proxies">LTE Proxies</Link></li>
              <li><Link to="/residential-proxies">Residential Proxies</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Company</h3>
            <ul>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Support</h3>
            <ul>
              <li><Link to="/faq">FAQ</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} DeathBy.Design. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default FAQPage; 