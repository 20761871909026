import React, { useState, useEffect } from 'react';
import { priceService } from '../services/priceService';
import '../styles/ProxyPurchaseModal.css';
import { BillingToggle } from './BillingToggle';
import { PlanToggle } from './PlanToggle';

const ProxyPurchaseModal = ({ type, onClose, onPurchase }) => {
  const [amount, setAmount] = useState(1);
  const [residentialPlan, setResidentialPlan] = useState('basic');
  const [billingPeriod, setBillingPeriod] = useState('month');
  const [country, setCountry] = useState('US');
  const [rotation, setRotation] = useState(30);
  const [prices, setPrices] = useState(null);
  const [loading, setLoading] = useState(false);

  const lteMonthlyPrices = {
    '5min': 119,
    '10min': 99,
    '30min': 79
  };

  const lteDailyPrices = {
    '5min': 14.99,
    '10min': 12.50,
    '30min': 9.99
  };

  const residentialPrices = {
    basic: { price: 2, min: 1, max: 30 },
    pro: { price: 1.5, min: 31, max: 99 },
    enterprise: { price: 1.3, min: 100, max: 500 }
  };

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const priceData = await priceService.getPrices();
        setPrices(priceData);
      } catch (error) {
        console.error('Failed to fetch prices:', error);
      }
    };
    fetchPrices();
  }, []);

  const calculatePrice = () => {
    if (!prices) return '0.00';
    
    if (type === 'lte') {
      const priceMap = billingPeriod === 'month' ? lteMonthlyPrices : lteDailyPrices;
      const basePrice = priceMap[`${rotation}min`];
      return (amount * basePrice).toFixed(2);
    }
    
    const residentialPrice = prices.find(p => p.type === 'residential');
    return residentialPrice ? (amount * residentialPrice.price_per_unit).toFixed(2) : '0.00';
  };

  const handlePurchase = async () => {
    setLoading(true);
    try {
      const price = parseFloat(calculatePrice());
      const purchaseData = {
        type: type,
        amount: type === 'lte' ? amount : price,
        days: type === 'lte' ? (billingPeriod === 'month' ? amount * 30 : amount) : null,
        plan: type === 'lte' ? null : residentialPlan,
        rotation: type === 'lte' ? rotation : null,
        billingPeriod: type === 'lte' ? billingPeriod : residentialPlan,
        country: type === 'lte' ? country : null
      };

      await onPurchase(purchaseData);
      onClose();
    } catch (error) {
      console.error('Purchase failed:', error);
      alert('Failed to process purchase. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>{type === 'lte' ? 'LTE Proxy Purchase' : 'Residential Proxy Purchase'}</h2>

        <div className="purchase-modal">
          {type === 'lte' ? (
            <>
              <div className="country-selector">
                <label>Country:</label>
                <select value={country} onChange={(e) => setCountry(e.target.value)} className="form-select">
                  <option value="US">United States</option>
                </select>
              </div>

              <BillingToggle period={billingPeriod} onChange={setBillingPeriod} />
              
              <div className="rotation-selector">
                <label>IP Rotation Interval:</label>
                <div className="rotation-options">
                  <button 
                    className={`rotation-option ${rotation === 5 ? 'selected' : ''}`}
                    onClick={() => setRotation(5)}
                  >
                    5 Minutes (${billingPeriod === 'month' ? '119' : '14.99'}/{billingPeriod})
                  </button>
                  <button 
                    className={`rotation-option ${rotation === 10 ? 'selected' : ''}`}
                    onClick={() => setRotation(10)}
                  >
                    10 Minutes (${billingPeriod === 'month' ? '99' : '12.50'}/{billingPeriod})
                  </button>
                  <button 
                    className={`rotation-option ${rotation === 30 ? 'selected' : ''}`}
                    onClick={() => setRotation(30)}
                  >
                    30 Minutes (${billingPeriod === 'month' ? '79' : '9.99'}/{billingPeriod})
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <PlanToggle 
                plan={residentialPlan} 
                onChange={(plan) => {
                  setResidentialPlan(plan);
                  setAmount(residentialPrices[plan].min);
                }} 
              />
              <div className="plan-details">
                <h3>{residentialPlan.charAt(0).toUpperCase() + residentialPlan.slice(1)} Plan</h3>
                <p>${residentialPrices[residentialPlan].price.toFixed(2)}/GB</p>
                <p>Range: {residentialPrices[residentialPlan].min}-{residentialPrices[residentialPlan].max}GB</p>
              </div>
            </>
          )}

          <div className="amount-selector">
            <label>
              {type === 'lte' ? (billingPeriod === 'month' ? 'Months' : 'Days') : 'GB'} Amount:
              <span className="amount-value">{amount}</span>
            </label>
            <input
              type="range"
              min={type === 'lte' ? 1 : residentialPrices[residentialPlan].min}
              max={type === 'lte' ? (billingPeriod === 'month' ? 12 : 100) : residentialPrices[residentialPlan].max}
              value={amount}
              onChange={(e) => setAmount(parseInt(e.target.value))}
              className="amount-slider"
            />
          </div>

          <div className="purchase-actions">
            <button className="cancel-button" onClick={onClose}>Cancel</button>
            <button 
              className="confirm-button" 
              onClick={handlePurchase}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Confirm Purchase'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProxyPurchaseModal;