import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import { FiCopy } from 'react-icons/fi';
import '../styles/ProxyGenerateModal.css';

const ProxyGenerateModal = ({ type, onClose, onGenerate }) => {
  const [country, setCountry] = useState('');
  const [format, setFormat] = useState('username:password@host:port');
  const [purchases, setPurchases] = useState([]);
  const [selectedPurchase, setSelectedPurchase] = useState('');
  const [existingProxy, setExistingProxy] = useState(null);
  const [generatedProxy, setGeneratedProxy] = useState('');
  const [copied, setCopied] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedPurchaseDetails, setSelectedPurchaseDetails] = useState({
    totalBandwidth: 0,
    usedBandwidth: 0,
    remainingBandwidth: 0,
    totalHours: 0,
    remainingHours: 0
  });
  const [serverLocation, setServerLocation] = useState('na');
  const [bandwidthInfo, setBandwidthInfo] = useState({
    gbLeft: 0,
    totalGb: 0,
    usedGb: 0
  });
  const [rotationLock, setRotationLock] = useState(null);
  const [clientIP, setClientIP] = useState('');
  const [ipSwitchLock, setIpSwitchLock] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCopyButton, setShowCopyButton] = useState(false);

  const formats = [
    'username:password@host:port',
    'host:port:username:password',
    'host|port|username|password'
  ];

  const serverLocations = [
    { id: 'na', name: 'North America' },
    { id: 'eu', name: 'Europe' },
    { id: 'as', name: 'Asia' }
  ];

  useEffect(() => {
    fetchPurchases();
    fetchCountries();
    const fetchBandwidthInfo = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user logged in');

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/proxy/usage/update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': user.id
          }
        });

        if (!response.ok) throw new Error('Failed to fetch bandwidth info');
        
        const data = await response.json();
        setBandwidthInfo({
          gbLeft: data.residential_gb_left || 0,
          totalGb: data.residential_total_gb || 0,
          usedGb: data.residential_used_gb || 0
        });
      } catch (error) {
        console.error('Error fetching bandwidth info:', error);
      }
    };

    fetchBandwidthInfo();
  }, []);

  const fetchCountries = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/proxy/countries`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': user.id
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Raw countries response:', data);
      
      const countriesArray = data?.ret_data?.ret_data || [];
      console.log('Processed countries array:', countriesArray);
      setCountries(countriesArray);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setCountries([]);
    }
  };

  useEffect(() => {
    if (selectedPurchase) {
      const fetchExistingProxy = async () => {
        try {
          const { data: { user } } = await supabase.auth.getUser();
          if (!user) throw new Error('No user logged in');

          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/proxy/details/${selectedPurchase}`, {
            headers: {
              'Authorization': user.id
            }
          });

          if (response.ok) {
            const data = await response.json();
            setExistingProxy({
              ...data.proxy,
              proxy_ip: 'lte.deathby.design'
            });
          }
        } catch (error) {
          console.error('Error fetching existing proxy:', error);
        }
      };

      fetchExistingProxy();
    }
  }, [selectedPurchase]);

  useEffect(() => {
    if (selectedPurchase) {
      checkExistingProxy();
      fetchPurchaseDetails();
    }
  }, [selectedPurchase]);

  const fetchPurchaseDetails = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data: purchaseData, error: purchaseError } = await supabase
        .from('proxy_purchases')
        .select('*, lte_proxy_credentials(*)')
        .eq('id', selectedPurchase)
        .single();

      if (purchaseError) throw purchaseError;

      if (type === 'lte' && purchaseData) {
        const firstGeneration = purchaseData.lte_proxy_credentials?.[0]?.created_at;
        const startDate = firstGeneration ? new Date(firstGeneration) : null;
        
        // Only calculate remaining time if proxy has been generated
        if (startDate) {
          const totalDays = purchaseData.amount;
          const daysElapsed = (new Date() - startDate) / (1000 * 60 * 60 * 24);
          const remainingDays = Math.max(0, totalDays - daysElapsed);

          setSelectedPurchaseDetails({
            totalBandwidth: 0,
            usedBandwidth: 0,
            remainingBandwidth: 0,
            totalHours: totalDays * 24,
            remainingHours: remainingDays * 24
          });
        } else {
          // If proxy hasn't been generated yet, show full time available
          setSelectedPurchaseDetails({
            totalBandwidth: 0,
            usedBandwidth: 0,
            remainingBandwidth: 0,
            totalHours: purchaseData.amount * 24,
            remainingHours: purchaseData.amount * 24
          });
        }
      } else {
        // Residential proxy logic remains the same
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/proxy/usage/purchase/${selectedPurchase}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': user.id
          }
        });

        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const usageData = await response.json();

        const totalBandwidth = purchaseData.amount || 0;
        const usedBandwidth = parseFloat(usageData?.ret_data?.used_bandwidth) || 0;
        const remainingBandwidth = Math.max(0, totalBandwidth - usedBandwidth);

        setSelectedPurchaseDetails({
          totalBandwidth,
          usedBandwidth,
          remainingBandwidth,
          totalHours: 0,
          remainingHours: 0
        });
      }
    } catch (error) {
      console.error('Error fetching purchase details:', error);
    }
  };

  const fetchPurchases = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      // Get all completed purchases with their credentials
      const { data: purchaseData, error } = await supabase
        .from('proxy_purchases')
        .select('*, lte_proxy_credentials(*)')
        .eq('user_id', user.id)
        .eq('type', type)
        .eq('status', 'completed')
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Format purchases for display
      const formattedPurchases = await Promise.all(purchaseData.map(async purchase => {
        if (type === 'lte') {
          const firstGeneration = purchase.lte_proxy_credentials?.[0]?.created_at;
          const startDate = firstGeneration ? new Date(firstGeneration) : null;
          const totalDays = purchase.amount;
          
          let isExpired = false;
          if (startDate) {
            const daysElapsed = (new Date() - startDate) / (1000 * 60 * 60 * 24);
            isExpired = daysElapsed >= totalDays;
          }

          return {
            id: purchase.id,
            label: `Purchase ${purchase.id.slice(0, 8)} - ${purchase.amount} Days (${purchase.rotation_minutes}min rotation)${isExpired ? ' - Expired' : startDate ? '' : ' - Not Generated'}`,
            isExpired
          };
        } else {
          // For residential proxies, check usage
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/proxy/usage/purchase/${purchase.id}`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': user.id
              }
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const usageData = await response.json();

            const totalBandwidth = purchase.amount || 0;
            const usedBandwidth = parseFloat(usageData?.ret_data?.used_bandwidth) || 0;
            const isExpired = usedBandwidth >= totalBandwidth;

            return {
              id: purchase.id,
              label: `Purchase ${purchase.id.slice(0, 8)} - ${purchase.amount}GB${isExpired ? ' - Expired' : ''}`,
              isExpired
            };
          } catch (error) {
            console.error('Error fetching residential proxy usage:', error);
            return {
              id: purchase.id,
              label: `Purchase ${purchase.id.slice(0, 8)} - ${purchase.amount}GB`,
              isExpired: false
            };
          }
        }
      }));

      setPurchases(formattedPurchases);
      
      // Select first non-expired purchase by default
      const firstValidPurchase = formattedPurchases.find(p => !p.isExpired);
      if (firstValidPurchase) {
        setSelectedPurchase(firstValidPurchase.id);
      }
    } catch (error) {
      console.error('Error fetching purchases:', error);
      setPurchases([]);
    }
  };

  const checkExistingProxy = async () => {
    try {
      const table = type === 'lte' ? 'lte_proxy_credentials' : 'proxy_credentials';
      const { data, error } = await supabase
        .from(table)
        .select('*')
        .eq('purchase_id', selectedPurchase)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      if (data) {
        const formattedProxy = formatProxy(data, format);
        setGeneratedProxy(formattedProxy);
        setExistingProxy(data);
      }
    } catch (error) {
      console.error('Error checking existing proxy:', error);
    }
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const endpoint = type === 'lte' 
        ? '/api/proxy/generate-lte'
        : '/api/proxy/generate';

      const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': user.id
        },
        body: JSON.stringify({
          purchaseId: selectedPurchase,
          country: country,
          format: format,
          serverLocation: serverLocation,
          clientIp: type === 'lte' ? clientIP : undefined
        })
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();

      // Format the proxy data before setting it
      const formattedProxy = formatProxy(data);
      setGeneratedProxy(formattedProxy);
      setShowCopyButton(true);
      await fetchPurchaseDetails();
    } catch (error) {
      console.error('Error generating proxy:', error);
      setError('Failed to generate proxy. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedProxy);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const formatProxy = (proxy) => {
    if (!proxy) return '';
    
    if (type === 'lte') {
      const proxyPort = proxy.proxy?.proxy_port || proxy.proxy_port;
      return proxyPort ? `lte.deathby.design:${proxyPort}` : '';
    }
    
    // Handle residential proxy format
    const proxyData = proxy.proxy || proxy;  // Handle both nested and direct formats
    if (!proxyData.port || !proxyData.username || !proxyData.password) return '';
    
    const port = proxyData.port;
    const host = `${serverLocation}.${proxyData.host || 'deathby.design'}`;
    const username = `${proxyData.username}-zone-resi-region-${country.toLowerCase()}`;
    const password = proxyData.password;
    
    switch (format) {
      case 'host:port:username:password':
        return `${host}:${port}:${username}:${password}`;
      case 'host|port|username|password':
        return `${host}|${port}|${username}|${password}`;
      default:
        return `${username}:${password}@${host}:${port}`;
    }
  };

  const handleRotate = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/proxy/rotate`, {
        method: 'POST',
        headers: {
          'Authorization': user.id,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          purchaseId: selectedPurchase
        })
      });

      const data = await response.json();
      if (!response.ok) {
        if (data.rotation_locked_until) {
          setRotationLock(new Date(data.rotation_locked_until));
        }
        throw new Error(data.error);
      }

      if (data.status === 'ok') {
        alert('Proxy rotated successfully');
        if (data.rotation_locked_until) {
          setRotationLock(new Date(data.rotation_locked_until));
        }
      }
    } catch (error) {
      console.error('Failed to rotate proxy:', error);
      alert(error.message);
    }
  };

  const isRotationLocked = () => {
    if (!rotationLock) return false;
    return new Date() < rotationLock;
  };

  const getTimeUntilRotation = () => {
    if (!rotationLock) return '';
    const diff = rotationLock - new Date();
    const minutes = Math.ceil(diff / (1000 * 60));
    return `${minutes} minutes`;
  };

  const handleSwitchIP = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/proxy/switch-ip`, {
        method: 'POST',
        headers: {
          'Authorization': user.id,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          purchaseId: selectedPurchase,
          newIp: clientIP
        })
      });

      const data = await response.json();
      if (!response.ok) {
        if (data.next_switch) {
          setIpSwitchLock(new Date(data.next_switch));
        }
        throw new Error(data.error);
      }

      alert('IP switched successfully');
    } catch (error) {
      console.error('Failed to switch IP:', error);
      alert(error.message);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Generate Proxy</h2>
        
        <div className="form-group">
          <label>Select Purchase</label>
          <select 
            value={selectedPurchase} 
            onChange={(e) => setSelectedPurchase(e.target.value)}
            className="form-select"
          >
            <option value="">Select a purchase</option>
            {purchases.map(purchase => (
              <option 
                key={purchase.id} 
                value={purchase.id}
                disabled={purchase.isExpired}
              >
                {purchase.label}
              </option>
            ))}
          </select>
        </div>

        {selectedPurchase && selectedPurchaseDetails && type === 'lte' && (
          <div className="bandwidth-info">
            <div className="bandwidth-text">
              {(selectedPurchaseDetails.remainingHours / 24).toPrecision(3)} days remaining of {selectedPurchaseDetails.totalHours / 24} days
            </div>
            <div className="bandwidth-bar">
              <div 
                className="bandwidth-progress"
                style={{ 
                  width: `${Math.max(0, Math.min(100, (selectedPurchaseDetails.remainingHours / selectedPurchaseDetails.totalHours) * 100))}%`
                }}
              />
            </div>
          </div>
        )}

        {selectedPurchase && selectedPurchaseDetails && type !== 'lte' && (
          <div className="bandwidth-info">
            <div className="bandwidth-text">
              {selectedPurchaseDetails.remainingBandwidth.toFixed(8)}GB remaining of {selectedPurchaseDetails.totalBandwidth.toFixed(2)}GB
            </div>
            <div className="bandwidth-bar">
              <div 
                className="bandwidth-progress"
                style={{ 
                  width: `${Math.max(0, Math.min(100, (selectedPurchaseDetails.remainingBandwidth / selectedPurchaseDetails.totalBandwidth) * 100))}%`
                }}
              />
            </div>
          </div>
        )}

        {!existingProxy && !generatedProxy && (
          <>
            {type !== 'lte' && (
              <>
                <div className="form-group">
                  <label>Server Location</label>
                  <div className="server-locations">
                    {serverLocations.map(location => (
                      <button
                        key={location.id}
                        className={`server-location ${serverLocation === location.id ? 'selected' : ''}`}
                        onClick={() => setServerLocation(location.id)}
                      >
                        {location.name}
                      </button>
                    ))}
                  </div>
                </div>

                <div className="form-group">
                  <label>Country</label>
                  <select 
                    value={country} 
                    onChange={(e) => setCountry(e.target.value)}
                    className="form-select"
                  >
                    <option value="">Select a country</option>
                    {countries.map(c => (
                      <option key={c.country_code} value={c.country_code}>
                        {c.country_name}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}

            {type === 'lte' && (
              <div className="form-group">
                <label>Country</label>
                <select 
                  value={country} 
                  onChange={(e) => setCountry(e.target.value)}
                  className="form-select"
                >
                  <option value="US">United States</option>
                </select>
              </div>
            )}
          </>
        )}

        {type !== 'lte' && (
          <div className="form-group">
            <label>Format</label>
            <select 
              value={format} 
              onChange={(e) => setFormat(e.target.value)}
              className="form-select"
            >
              {formats.map(f => (
                <option key={f} value={f}>{f}</option>
              ))}
            </select>
          </div>
        )}

        {generatedProxy && (
          <div className="proxy-result">
            <label>Your Proxy</label>
            <div className="proxy-box">
              <code>{generatedProxy}</code>
              <button 
                className="copy-button" 
                onClick={handleCopy}
                title="Copy to clipboard"
              >
                <FiCopy />
                {copied && <span className="copied-tooltip">Copied!</span>}
              </button>
            </div>
          </div>
        )}

        {type === 'lte' && (
          <div className="ip-switch-container">
            <input
              type="text"
              placeholder="Enter your IP address"
              value={clientIP}
              onChange={(e) => setClientIP(e.target.value)}
              className="ip-input"
            />
            {generatedProxy && (
              <>
                <button
                  onClick={handleSwitchIP}
                  disabled={ipSwitchLock && new Date() < new Date(ipSwitchLock)}
                  className="switch-ip-button"
                >
                  Switch IP
                </button>
                {ipSwitchLock && new Date() < new Date(ipSwitchLock) && (
                  <p className="lock-message">
                    IP switch available in {Math.ceil((new Date(ipSwitchLock) - new Date()) / (1000 * 60))} minutes
                  </p>
                )}
              </>
            )}
          </div>
        )}

        {type === 'lte' && generatedProxy && (
          <div className="rotation-controls">
            <button 
              className="rotate-button"
              onClick={handleRotate}
              disabled={isRotationLocked()}
            >
              Rotate Proxy
            </button>
            {isRotationLocked() && (
              <div className="rotation-lock-timer">
                Next rotation available in: {getTimeUntilRotation()}
              </div>
            )}
          </div>
        )}

        <div className="modal-actions" style={{ marginTop: 'auto' }}>
          <button 
            className="cancel-button" 
            onClick={onClose}
            style={{ flex: 1 }}
          >
            Close
          </button>
          {!generatedProxy && (
            <button 
              className="generate-button" 
              onClick={handleGenerate}
              disabled={
                !selectedPurchase || 
                (type !== 'lte' && !country) ||
                (type === 'lte' && !existingProxy && !clientIP)
              }
              style={{ flex: 1 }}
            >
              {existingProxy ? 'Show Proxy' : 'Generate New Proxy'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProxyGenerateModal;