import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/ProxyPurchasePage.css';
import { paymentService } from '../services/payment';
import { priceService } from '../services/priceService';
import usePageLoading from '../hooks/usePageLoading';
import LoadingPage from './LoadingPage';
import { useLocation } from 'react-router-dom';
import { BillingToggle } from './BillingToggle';
import { PlanToggle } from './PlanToggle';

const ProxyPurchasePage = ({ type }) => {
  const location = useLocation();
  const selectedPlan = location.state?.selectedPlan;
  
  const [amount, setAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rotation, setRotation] = useState(selectedPlan?.rotation || 30);
  const [prices, setPrices] = useState(null);
  const [billingPeriod, setBillingPeriod] = useState(selectedPlan?.billingPeriod || 'day');
  const [country, setCountry] = useState('US');
  const [residentialPlan, setResidentialPlan] = useState(selectedPlan?.plan || 'basic');
  const isLoading = usePageLoading();

  const lteMonthlyPrices = {
    '5min': 119,
    '10min': 99,
    '30min': 79
  };

  const lteDailyPrices = {
    '5min': 14.99,
    '10min': 12.50,
    '30min': 9.99
  };

  const residentialPrices = {
    basic: { price: 2.00, min: 1, max: 30 },
    pro: { price: 1.50, min: 31, max: 99 },
    enterprise: { price: 1.30, min: 100, max: 1000 }
  };

  useEffect(() => {
    if (selectedPlan) {
      if (type === 'lte') {
        setRotation(selectedPlan.rotation);
        setBillingPeriod(selectedPlan.billingPeriod);
      } else {
        setResidentialPlan(selectedPlan.plan || 'basic');
        setAmount(residentialPrices[selectedPlan.plan || 'basic'].min);
      }
    }
  }, [selectedPlan, type]);

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const priceData = await priceService.getPrices();
        setPrices(priceData);
      } catch (error) {
        console.error('Failed to fetch prices:', error);
      }
    };
    fetchPrices();
  }, []);

  const calculatePrice = () => {
    if (!prices) return '0.00';
    
    if (type === 'lte') {
      const priceMap = billingPeriod === 'month' ? lteMonthlyPrices : lteDailyPrices;
      const basePrice = priceMap[`${rotation}min`];
      return (amount * basePrice).toFixed(2);
    }
    
    return (amount * residentialPrices[residentialPlan].price).toFixed(2);
  };

  const handlePurchase = async () => {
    setLoading(true);
    try {
      const price = parseFloat(calculatePrice());
      const purchaseData = {
        type,
        amount: price,
        days: type === 'lte' ? (billingPeriod === 'month' ? amount * 30 : amount) : null,
        rotation: type === 'lte' ? rotation : null,
        billingPeriod: type === 'lte' ? billingPeriod : residentialPlan,
        country: type === 'lte' ? country : null
      };
      await paymentService.createPayment(purchaseData);
    } catch (error) {
      console.error('Purchase failed:', error);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="page-container">
      <Header />
      
      <section className="dashboard-hero-section">
        <h1>{type === 'lte' ? 'LTE Proxy Purchase' : 'Residential Proxy Purchase'}</h1>
        <p>{type === 'lte' ? 'High-speed mobile proxy network with manual IP rotation' : 'Access to millions of residential IPs worldwide'}</p>
      </section>

      <div className="purchase-container">
        <div className="purchase-card">
          {type === 'lte' ? (
            <>
              <div className="country-selector">
                <label>Country:</label>
                <select 
                  value={country} 
                  onChange={(e) => setCountry(e.target.value)}
                  className="form-select"
                >
                  <option value="US">United States</option>
                </select>
              </div>

              <BillingToggle period={billingPeriod} onChange={setBillingPeriod} />
              <div className="rotation-selector">
                <label>IP Rotation Interval:</label>
                <div className="rotation-options">
                  <button 
                    className={`rotation-option ${rotation === 5 ? 'selected' : ''}`}
                    onClick={() => setRotation(5)}
                  >
                    5 Minutes (${billingPeriod === 'month' ? '119' : '14.99'}/{billingPeriod})
                  </button>
                  <button 
                    className={`rotation-option ${rotation === 10 ? 'selected' : ''}`}
                    onClick={() => setRotation(10)}
                  >
                    10 Minutes (${billingPeriod === 'month' ? '99' : '12.50'}/{billingPeriod})
                  </button>
                  <button 
                    className={`rotation-option ${rotation === 30 ? 'selected' : ''}`}
                    onClick={() => setRotation(30)}
                  >
                    30 Minutes (${billingPeriod === 'month' ? '79' : '9.99'}/{billingPeriod})
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <PlanToggle plan={residentialPlan} onChange={(plan) => {
                setResidentialPlan(plan);
                setAmount(residentialPrices[plan].min);
              }} />
              <div className="plan-details">
                <h3>{residentialPlan.charAt(0).toUpperCase() + residentialPlan.slice(1)} Plan</h3>
                <p>${residentialPrices[residentialPlan].price.toFixed(2)}/GB</p>
                <p>Range: {residentialPrices[residentialPlan].min}-{residentialPrices[residentialPlan].max}GB</p>
              </div>
            </>
          )}

          <div className="amount-selector">
            <label>
              {type === 'lte' ? (billingPeriod === 'month' ? 'Months' : 'Days') : 'GB'} Amount:
              <span className="amount-value">{amount}</span>
            </label>
            <input
              type="range"
              min={type === 'lte' ? 1 : residentialPrices[residentialPlan].min}
              max={type === 'lte' ? (billingPeriod === 'month' ? 12 : 100) : residentialPrices[residentialPlan].max}
              value={amount}
              onChange={(e) => setAmount(parseInt(e.target.value))}
              className="amount-slider"
            />
          </div>

          <div className="total-price">
            <span>Total Price: ${calculatePrice()}</span>
          </div>

          <button 
            className="purchase-button" 
            onClick={handlePurchase}
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Purchase Now'}
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProxyPurchasePage; 