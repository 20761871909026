import { supabase } from '../supabase';

export const priceService = {
    async getPrices() {
        const { data, error } = await supabase
            .from('product_prices')
            .select('*');
            
        if (error) throw error;
        return data;
    }
}; 