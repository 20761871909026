import React from 'react';
import '../styles/ProxyCard.css';

const ProxyCard = ({ type, data, onPurchase, onGenerateProxy }) => {
  const formatValue = (value) => {
    if (typeof value !== 'number') return '0';
    
    if (type === 'lte') {
      if (value < 1) {
        // Convert to hours if less than a day
        const hours = value * 24;
        return `${hours.toPrecision(3)}h`;
      }
      return value.toPrecision(3);
    }
    
    return value.toFixed(2);
  };

  const getTimeLabel = () => {
    if (type === 'lte') {
      return data.daysLeft < 1 ? 'Hours Left' : 'Days Left';
    }
    return 'GB Left';
  };

  const getValue = () => {
    if (type === 'lte') {
      return formatValue(data.daysLeft);
    }
    return formatValue(data.gbLeft);
  };

  return (
    <div className="proxy-card">
      <div className="proxy-header">
        <h3>{type === 'lte' ? 'LTE Proxy' : 'Residential Proxy'}</h3>
        <div className="proxy-badge">
          {type === 'lte' ? 'Mobile IP' : 'Home IP'}
        </div>
      </div>
      
      <div className="proxy-stats">
        <div className="stat-item">
          <span className="stat-label">
            {getTimeLabel()}
          </span>
          <span className="stat-value">
            {getValue()}
          </span>
        </div>
        
        <div className="stat-item">
          <span className="stat-label">Status</span>
          <span className={`status-badge ${data.active ? 'active' : 'inactive'}`}>
            {data.active ? 'Active' : 'Inactive'}
          </span>
        </div>
      </div>

      <div className="proxy-details">
        <div className="detail-row">
          <span>IP Rotation</span>
          <span>{type === 'lte' ? 'Every 5-15 Minutes' : 'Every request'}</span>
        </div>
        <div className="detail-row">
          <span>Locations</span>
          <span>200+ Countries</span>
        </div>
        <div className="detail-row">
          <span>Threads</span>
          <span>Unlimited</span>
        </div>
      </div>

      {data.active && (
        <button 
          className="generate-button"
          onClick={() => onGenerateProxy(type)}
        >
          Generate Proxy
        </button>
      )}
      
      <button 
        className="purchase-button"
        onClick={() => onPurchase(type)}
      >
        Purchase More
      </button>
    </div>
  );
};

export default ProxyCard;