import React from 'react';

export const PlanToggle = ({ plan, onChange }) => {
  return (
    <div className="plan-toggle-container">
      <div className="plan-toggle">
        <div className="toggle-slider" data-active={plan} />
        <button 
          className={plan === 'basic' ? 'active' : ''} 
          onClick={() => onChange('basic')}
        >
          Basic
        </button>
        <button 
          className={plan === 'pro' ? 'active' : ''} 
          onClick={() => onChange('pro')}
        >
          Pro
        </button>
        <button 
          className={plan === 'enterprise' ? 'active' : ''} 
          onClick={() => onChange('enterprise')}
        >
          Enterprise
        </button>
      </div>
    </div>
  );
}; 