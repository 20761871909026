// frontend/src/pages/ProfilePage.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import Header from '../components/Header';
import '../styles/ProfilePage.css';
import usePageLoading from '../hooks/usePageLoading';
import LoadingPage from '../components/LoadingPage';

const ProfilePage = () => {
  const isLoading = usePageLoading();
  const [user, setUser] = useState(null);
  const [proxyStats, setProxyStats] = useState({
    lte: { totalDays: 0, daysLeft: 0 },
    residential: { totalGB: 0, gbLeft: 0 }
  });
  const [purchaseHistory, setPurchaseHistory] = useState([]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user logged in');

        // Fetch user proxies for current values
        const { data: proxyData, error: proxyError } = await supabase
          .from('user_proxies')
          .select('*')
          .eq('user_id', user.id)
          .single();

        if (proxyError && proxyError.code !== 'PGRST116') {
          throw proxyError;
        }

        // Fetch purchase history
        const { data: purchases, error: purchaseError } = await supabase
          .from('proxy_purchases')
          .select('*')
          .eq('user_id', user.id)
          .eq('status', 'completed')
          .order('created_at', { ascending: false });

        if (purchaseError) throw purchaseError;

        // Calculate totals from purchase history
        const stats = {
          lte: { totalDays: 0, daysLeft: proxyData?.lte_days_left || 0 },
          residential: { totalGB: 0, gbLeft: proxyData?.residential_gb_left || 0 }
        };

        purchases.forEach(purchase => {
          if (purchase.type === 'residential') {
            stats.residential.totalGB += purchase.amount;
          } else if (purchase.type === 'lte') {
            stats.lte.totalDays += purchase.amount;
          }
        });

        setProxyStats(stats);
        setPurchaseHistory(purchases);
      } catch (error) {
        console.error('Error loading profile:', error);
      }
    };

    fetchProfile();
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="page-container">
      <Header />
      <div className="content-container">
        <h2 className="page-title">Profile</h2>
        
        <div className="stats-grid">
          <div className="stats-card">
            <h3>LTE Proxy Stats</h3>
            <div className="stat-row">
              <span>Total Days Purchased:</span>
              <span>{proxyStats.lte.totalDays}</span>
            </div>
            <div className="stat-row">
              <span>Days Remaining:</span>
              <span>{proxyStats.lte.daysLeft}</span>
            </div>
          </div>

          <div className="stats-card">
            <h3>Residential Proxy Stats</h3>
            <div className="stat-row">
              <span>Total GB Purchased:</span>
              <span>{proxyStats.residential.totalGB}</span>
            </div>
            <div className="stat-row">
              <span>GB Remaining:</span>
              <span>{proxyStats.residential.gbLeft}</span>
            </div>
          </div>
        </div>

        <h3 className="section-title">Purchase History</h3>
        <div className="history-grid">
          {purchaseHistory.map((purchase) => (
            <div key={purchase.id} className="history-card">
              <div className="purchase-type">
                {purchase.type === 'lte' ? 'LTE Proxy' : 'Residential Proxy'}
              </div>
              <div className="purchase-amount">
                {purchase.type === 'lte' 
                  ? `${purchase.amount} Days` 
                  : `${purchase.amount} GB`}
              </div>
              <div className="purchase-date">
                {new Date(purchase.created_at).toLocaleDateString()}
              </div>
              <div className="purchase-price">
                ${purchase.price}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;