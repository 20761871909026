import React from 'react';

export const BillingToggle = ({ period, onChange }) => {
  return (
    <div className="billing-toggle-container">
      <div className="billing-toggle">
        <div className="toggle-slider" data-active={period} />
        <button 
          className={period === 'day' ? 'active' : ''} 
          onClick={() => onChange('day')}
        >
          Daily
        </button>
        <button 
          className={period === 'month' ? 'active' : ''} 
          onClick={() => onChange('month')}
        >
          Monthly
        </button>
      </div>
    </div>
  );
}; 