import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Auth.css';

const EmailConfirmation = ({ email, onClose }) => {
  return (
    <div className="email-confirmation-overlay">
      <div className="email-confirmation-modal">
        <h2>Check your email</h2>
        <p className="auth-subtitle">
          We sent a confirmation link to<br />
          <strong>{email}</strong>
        </p>
        <p className="confirmation-text">
          Please check your email and click the confirmation link to complete your registration.
        </p>
        <button className="confirm-button" onClick={onClose}>
          Got it
        </button>
      </div>
    </div>
  );
};

export default EmailConfirmation;