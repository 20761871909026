import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Header from '../components/Header';
import '../styles/LandingPage.css';
import usePageLoading from '../hooks/usePageLoading';
import LoadingPage from '../components/LoadingPage';

const LandingPage = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const isLoading = usePageLoading();

  const handleGetStarted = () => {
    if (isAuthenticated) {
      navigate('/dashboard');
    } else {
      navigate('/register');
    }
  };

  const handleCTAClick = (e) => {
    e.preventDefault();
    if (isAuthenticated) {
      navigate('/dashboard');
    } else {
      navigate('/register');
    }
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="landing-container">
      <Header />
      
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Unlock the Future of Online Privacy</h1>
          <p>Experience our high-performance proxy solutions designed for modern web needs</p>
          <button onClick={handleGetStarted} className="cta-button">
            {isAuthenticated ? 'Go to Dashboard' : 'Get Started'}
          </button>
        </div>
        <div className="hero-graphics">
          <div className="orbit-circle"></div>
          <div className="orbit-circle-inner"></div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <h2>Proxy Services</h2>
        <div className="features-grid">
          <div className="feature-card">
            <div className="feature-icon">🔒</div>
            <h3>Digital Privacy</h3>
            <p>Advanced encryption and secure protocols</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">🌐</div>
            <h3>Global Network</h3>
            <p>Access to 200+ countries worldwide</p>
          </div>
          <div className="feature-card">
            <div className="feature-icon">⚡</div>
            <h3>High Speed</h3>
            <p>Lightning-fast connections and low latency</p>
          </div>
        </div>
      </section>

      {/* Proxy Types Section */}
      <section className="proxy-types-section">
        <div className="proxy-type">
          <div className="proxy-type-content">
            <h2>LTE Proxy Solutions</h2>
            <p>High-speed mobile proxy network with manual IP rotation</p>
            <ul className="proxy-features">
              <li>Manual IP Rotation</li>
              <li>Dedicated Mobile IPs</li>
              <li>24/7 Technical Support</li>
            </ul>
            <Link to="/lte-proxies" className="learn-more-button">Learn More</Link>
          </div>
          <div className="proxy-type-graphic lte-graphic">
            <div className="rotating-circle"></div>
          </div>
        </div>

        <div className="proxy-type reverse">
          <div className="proxy-type-content">
            <h2>Residential Proxy</h2>
            <p>Access to millions of residential IPs worldwide</p>
            <ul className="proxy-features">
              <li>Real Residential IPs</li>
              <li>Geo-Targeting</li>
              <li>Unlimited Threads</li>
            </ul>
            <Link to="/residential-proxies" className="learn-more-button">Learn More</Link>
          </div>
          <div className="proxy-type-graphic residential-graphic">
            <div className="globe-effect"></div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta-section">
        <h2>Ready to Get Started?</h2>
        <p>Join thousands of satisfied users worldwide</p>
        <div className="cta-buttons">
          <a href="#" onClick={handleCTAClick} className="primary-button">
            {isAuthenticated ? 'Go to Dashboard' : 'Sign Up Now'}
          </a>
          <Link to="/pricing" className="secondary-button">View Pricing</Link>
        </div>
      </section>

      <footer className="landing-footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Products</h3>
            <ul>
              <li><Link to="/lte-proxies">LTE Proxies</Link></li>
              <li><Link to="/residential-proxies">Residential Proxies</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Company</h3>
            <ul>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Support</h3>
            <ul>
              <li><Link to="/faq">FAQ</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} DeathBy.Design. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;