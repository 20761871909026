import { useState, useEffect } from 'react';

const usePageLoading = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100); // Reduced from 1500ms to 500ms

    return () => clearTimeout(timer);
  }, []);

  return isLoading;
};

export default usePageLoading; 