// frontend/src/pages/LoginPage.js
import React, { useState } from 'react';
import { supabase } from '../supabase';
import { Link, useNavigate } from 'react-router-dom';
import { FiMail, FiLock, FiEye, FiEyeOff } from 'react-icons/fi';
import '../styles/Auth.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const { data, error } = await supabase.auth.signInWithPassword({ 
        email, 
        password 
      });

      if (error) {
        console.log('Login error:', error);
        setError(error.message || 'Invalid login credentials');
        setLoading(false);
        return;
      }

      if (!data?.user) {
        setError('Invalid login credentials');
        setLoading(false);
        return;
      }

      navigate('/');
    } catch (err) {
      console.error('Unexpected error:', err);
      setError('An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-orbital"></div>
      <div className="auth-orbital-inner"></div>
      <div className="auth-header">
        <Link to="/" className="auth-logo">
          DeathBy.Design
        </Link>
      </div>
      <div className="auth-content">
        {error && (
          <div className="error-popup">
            <p>{error}</p>
            <button onClick={() => setError('')}>×</button>
          </div>
        )}
        <div className="auth-card">
          <h2>Welcome back</h2>
          <p className="auth-subtitle">Access your dashboard</p>
          
          <div className="form-group">
            <label className="form-label">Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              className="form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group password-group">
            <label className="form-label">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              className="form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>

          <button className="auth-button" onClick={handleLogin}>
            Sign in
          </button>

          <div className="auth-footer">
            Don't have an account?
            <Link to="/register" className="auth-link">Register</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;