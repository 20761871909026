import { supabase } from '../supabase';

class PaymentService {
    async createPayment(type, amount, rotation = null) {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
            alert('Please log in to make a purchase');
            throw new Error('No user logged in');
        }

        try {
            const apiUrl = process.env.REACT_APP_API_URL || 'https://deathby.design';
            const requestUrl = `${apiUrl}/api/payment/create`;
            
            console.log('Attempting payment request to:', requestUrl);
            
            const requestBody = {
                amount,
                type,
                rotation,
                timestamp: Date.now(),
                userId: user.id,
                email: user.email,
            };
            
            console.log('Request body:', requestBody);
            
            const response = await fetch(requestUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.id}`,
                },
                body: JSON.stringify(requestBody),
            }).catch(error => {
                console.error('Fetch error:', error);
                throw new Error(`Network error: ${error.message}`);
            });

            console.log('Response received:', response);

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Server error response:', errorText);
                throw new Error(`Server error: ${response.status} - ${errorText}`);
            }

            const data = await response.json().catch(error => {
                console.error('JSON parse error:', error);
                throw new Error('Failed to parse server response');
            });

            console.log('Parsed response data:', data);

            if (!data.payment_url) {
                throw new Error('Invalid response: missing payment URL');
            }

            window.location.href = data.payment_url;
            return data;
        } catch (error) {
            console.error('Payment creation failed:', error);
            alert(`Payment failed: ${error.message}`);
            throw error;
        }
    }
}

export const paymentService = new PaymentService();