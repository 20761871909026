import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProxyCard from '../components/ProxyCard';
import ProxyPurchaseModal from '../components/ProxyPurchaseModal';
import ProxyGenerateModal from '../components/ProxyGenerateModal';
import '../styles/DashboardPage.css';
import { paymentService } from '../services/payment';
import LoadingPage from '../components/LoadingPage';

const DashboardPage = () => {
  const [proxyData, setProxyData] = useState({
    lte: { daysLeft: 0, active: false },
    residential: { 
      gbLeft: 0, 
      totalGb: 0,
      usedGb: 0,
      active: false 
    }
  });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [showGenerateModal, setShowGenerateModal] = useState(false);

  const fetchProxyData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const { data: ltePurchase, error: purchaseError } = await supabase
        .from('proxy_purchases')
        .select('*, lte_proxy_credentials(*)')
        .eq('user_id', user.id)
        .eq('type', 'lte')
        .eq('status', 'completed')
        .order('created_at', { ascending: false })
        .limit(1)
        .single();

      let lteDaysLeft = 0;
      if (ltePurchase) {
        const firstGeneration = ltePurchase.lte_proxy_credentials?.[0]?.created_at;
        const startDate = firstGeneration ? new Date(firstGeneration) : new Date(ltePurchase.created_at);
        const totalDays = ltePurchase.amount;
        const daysElapsed = (new Date() - startDate) / (1000 * 60 * 60 * 24);
        lteDaysLeft = Math.max(0, totalDays - daysElapsed);
      }

      // Get residential proxy data
      const { data: proxyData, error: proxyError } = await supabase
        .from('user_proxies')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (proxyError) throw proxyError;

      setProxyData({
        lte: { 
          daysLeft: lteDaysLeft,
          active: lteDaysLeft > 0 
        },
        residential: { 
          gbLeft: proxyData?.residential_gb_left || 0,
          totalGb: proxyData?.residential_total_gb || 0,
          usedGb: proxyData?.residential_used_gb || 0,
          active: (proxyData?.residential_gb_left || 0) > 0 
        }
      });
    } catch (error) {
      console.error('Error fetching proxy data:', error);
      setProxyData({
        lte: { daysLeft: 0, active: false },
        residential: { gbLeft: 0, totalGb: 0, usedGb: 0, active: false }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProxyData();
  }, []);

  useEffect(() => {
    const updateProxyUsage = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('No user logged in');

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/proxy/usage/update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': user.id
          }
        });

        if (!response.ok) {
          throw new Error('Failed to update proxy usage');
        }

        fetchProxyData();
      } catch (error) {
        console.error('Error updating proxy usage:', error);
      }
    };

    updateProxyUsage();
  }, []);

  const handlePurchase = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleConfirmPurchase = async (type, amount, rotation) => {
    try {
        await paymentService.createPayment(type, amount, rotation);
    } catch (error) {
        console.error('Purchase failed:', error);
    }
  };

  const handleGenerateProxy = async (options) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No user logged in');

      const endpoint = options.type === 'lte' ? 'generate-lte' : 'generate';
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/proxy/${endpoint}`, {
        method: 'POST',
        headers: {
          'Authorization': user.id,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          purchaseId: options.purchaseId,
          country: options.country,
          format: options.format,
          clientIp: options.clientIp
        })
      });

      if (!response.ok) throw new Error('Failed to generate proxy');
      return await response.json();
    } catch (error) {
      console.error('Failed to generate proxy:', error);
      throw error;
    }
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="landing-container">
      <Header />
      
      <section className="dashboard-hero-section">
        <h1>Your Proxy Dashboard</h1>
        <p>Manage and monitor your proxy services</p>
      </section>

      <div className="dashboard-content">
        <div className="proxy-grid">
          <div>
            <ProxyCard 
              type="lte" 
              data={proxyData.lte} 
              onPurchase={handlePurchase}
              onGenerateProxy={() => {
                setModalType('lte');
                setShowGenerateModal(true);
              }}
            />
          </div>

          <div>
            <ProxyCard 
              type="residential" 
              data={proxyData.residential} 
              onPurchase={handlePurchase}
              onGenerateProxy={() => {
                setModalType('residential');
                setShowGenerateModal(true);
              }}
            />
          </div>
        </div>
      </div>

      <Footer />

      {showModal && (
        <ProxyPurchaseModal 
          type={modalType} 
          onClose={() => setShowModal(false)} 
          onPurchase={handleConfirmPurchase} 
        />
      )}
      {showGenerateModal && (
        <ProxyGenerateModal
          type={modalType}
          onClose={() => setShowGenerateModal(false)}
          onGenerate={handleGenerateProxy}
        />
      )}
    </div>
  );
};

export default DashboardPage; 