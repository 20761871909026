// frontend/src/pages/RegisterPage.js
import React, { useState } from 'react';
import { supabase } from '../supabase';
import { Link, useNavigate } from 'react-router-dom';
import { FiMail, FiLock, FiUser, FiEye, FiEyeOff } from 'react-icons/fi';
import '../styles/Auth.css';
import StyleSelectionModal from '../components/StyleSelectionModal';
import EmailConfirmation from '../components/EmailConfirmation';

const RegisterPage = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // First check if username exists
      const { data: existingUsername } = await supabase
        .from('profiles')
        .select('username')
        .eq('username', username)
        .single();

      if (existingUsername) {
        throw new Error('Username is already taken');
      }

      // Create the user
      const { data, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            username: username
          },
          emailRedirectTo: 'https://deathby.design/'
        }
      });

      if (signUpError) throw signUpError;

      if (data?.user) {
        // Sign in to get session
        const { error: signInError } = await supabase.auth.signInWithPassword({
          email,
          password
        });

        if (signInError) throw signInError;

        // Now create profile with active session
        const { error: profileError } = await supabase
          .from('profiles')
          .insert([
            {
              id: data.user.id,
              email,
              username
            }
          ])
          .select()
          .single();

        if (profileError) {
          console.error('Profile creation error:', profileError);
          throw new Error('Failed to create profile. Please try again later.');
        }

        // Sign out since email needs verification
        await supabase.auth.signOut();
        
        console.log('Profile created, showing confirmation page');
        setIsRegistered(true);
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-orbital"></div>
      <div className="auth-orbital-inner"></div>
      <div className="auth-header">
        <Link to="/" className="auth-logo">
          DeathBy.Design
        </Link>
      </div>
      <div className="auth-content">
        {error && (
          <div className="error-popup">
            <p>{error}</p>
            <button onClick={() => setError('')}>×</button>
          </div>
        )}
        <div className="auth-card">
          <h2>Create Account</h2>
          <p className="auth-subtitle">Get started with DeathBy.Design</p>
          
          <div className="form-group">
            <label className="form-label">Username</label>
            <input
              type="text"
              placeholder="Choose a username"
              className="form-input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label className="form-label">Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              className="form-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group password-group">
            <label className="form-label">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Create a password"
              className="form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>

          <div className="form-group password-group">
            <label className="form-label">Confirm Password</label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm your password"
              className="form-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              type="button"
              className="password-toggle"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
            </button>
          </div>

          <button className="auth-button" onClick={handleRegister}>
            Create account
          </button>

          <div className="auth-footer">
            Already have an account?
            <Link to="/login" className="auth-link">Sign in</Link>
          </div>
        </div>
        {isRegistered && (
          <EmailConfirmation 
            email={email} 
            onClose={() => navigate('/login')} 
          />
        )}
      </div>
    </div>
  );
};

export default RegisterPage;