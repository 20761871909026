import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Header from '../components/Header';
import '../styles/PricingPage.css';
import usePageLoading from '../hooks/usePageLoading';
import LoadingPage from '../components/LoadingPage';

const BillingToggle = ({ period, onChange }) => {
  return (
    <div className="billing-toggle-container">
      <div className="billing-toggle">
        <div className="toggle-slider" data-active={period} />
        <button 
          className={period === 'day' ? 'active' : ''} 
          onClick={() => onChange('day')}
        >
          Daily
        </button>
        <button 
          className={period === 'month' ? 'active' : ''} 
          onClick={() => onChange('month')}
        >
          Monthly
        </button>
      </div>
    </div>
  );
};

const PricingPage = () => {
  const isLoading = usePageLoading();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [billingPeriod, setBillingPeriod] = useState('month');

  if (isLoading) {
    return <LoadingPage />;
  }

  const prices = {
    lte: {
      '5min': { month: 119, day: 14.99 },
      '10min': { month: 99, day: 12.50 },
      '30min': { month: 79, day: 9.99 }
    }
  };

  const handleSubscribe = (plan) => {
    const [type, planType] = plan.split('-');
    let config;
    
    if (type === 'lte') {
      const rotationMinutes = parseInt(planType.replace('min', ''));
      config = {
        type,
        rotation: rotationMinutes,
        billingPeriod
      };
    } else if (type === 'residential') {
      config = {
        type,
        plan: planType,
        billingPeriod: planType
      };
    }

    if (isAuthenticated) {
      navigate(`/${type}-proxies`, { 
        state: { 
          selectedPlan: config
        } 
      });
    } else {
      navigate('/register', { 
        state: { 
          selectedPlan: config,
          redirectTo: `/${type}-proxies`
        } 
      });
    }
  };

  return (
    <div className="landing-container">
      <Header />
      
      {/* Hero Section */}
      <section className="pricing-hero-section">
        <h1>Simple, Transparent Pricing</h1>
        <p>Choose the plan that works best for you</p>
      </section>

      {/* LTE Proxies Section */}
      <section className="proxy-pricing-section">
        <h2>LTE Proxy Plans</h2>
        <p className="section-subtitle">Fully-Anonymous mobile proxy network with customizable IP rotation</p>
        
        <BillingToggle period={billingPeriod} onChange={setBillingPeriod} />

        <div className="pricing-grid">
          <div className="pricing-card">
            <div className="pricing-header">
              <h3>5 Minute Rotation</h3>
              <div className="price">
                <span className="amount">${billingPeriod === 'month' ? '119' : '14.99'}</span>
                <span className="period">{billingPeriod}</span>
              </div>
            </div>
            <ul className="pricing-features">
              <li>Fastest IP Rotation</li>
              <li>Unlimited Rotation</li>
              <li>Priority Support</li>
              <li>All Locations</li>
            </ul>
            <button onClick={() => handleSubscribe('lte-5min')} className="pricing-cta">
              {isAuthenticated ? 'Upgrade Now' : 'Get Started'}
            </button>
          </div>

          <div className="pricing-card featured">
            <div className="pricing-header">
              <h3>10 Minute Rotation</h3>
              <div className="price">
                <span className="amount">${billingPeriod === 'month' ? '99' : '12.50'}</span>
                <span className="period">{billingPeriod}</span>
              </div>
            </div>
            <ul className="pricing-features">
              <li>Balanced Performance</li>
              <li>Unlimited Rotation</li>
              <li>Priority Support</li>
              <li>All Locations</li>
            </ul>
            <button onClick={() => handleSubscribe('lte-10min')} className="pricing-cta featured">
              {isAuthenticated ? 'Upgrade Now' : 'Get Started'}
            </button>
          </div>

          <div className="pricing-card">
            <div className="pricing-header">
              <h3>30 Minute Rotation</h3>
              <div className="price">
                <span className="amount">${billingPeriod === 'month' ? '79' : '9.99'}</span>
                <span className="period">{billingPeriod}</span>
              </div>
            </div>
            <ul className="pricing-features">
              <li>Extended Sessions</li>
              <li>Unlimited Rotation</li>
              <li>Priority Support</li>
              <li>All Locations</li>
            </ul>
            <button onClick={() => handleSubscribe('lte-30min')} className="pricing-cta">
              {isAuthenticated ? 'Upgrade Now' : 'Get Started'}
            </button>
          </div>
        </div>
      </section>

      {/* Residential Proxies Section */}
      <section className="proxy-pricing-section">
        <h2>Residential Proxy Plans</h2>
        <p className="section-subtitle">Access to millions of residential IPs worldwide</p>
        <div className="pricing-grid">
          <div className="pricing-card">
            <div className="pricing-header">
              <h3>Up to 30GB</h3>
              <div className="price">
                <span className="amount">$2</span>
                <span className="period">/GB</span>
              </div>
            </div>
            <ul className="pricing-features">
              <li>Rotating IPs</li>
              <li>Priority Support</li>
              <li>Unlimited Threads</li>
              <li>Perfect for testing</li>
            </ul>
            <button onClick={() => handleSubscribe('residential-basic')} className="pricing-cta">
              {isAuthenticated ? 'Purchase Now' : 'Get Started'}
            </button>
          </div>

          <div className="pricing-card featured">
            <div className="pricing-header">
              <h3>31GB - 99GB</h3>
              <div className="price">
                <span className="amount">$1.50</span>
                <span className="period">/GB</span>
              </div>
            </div>
            <ul className="pricing-features">
              <li>Rotating IPs</li>
              <li>Priority Support</li>
              <li>Unlimited Threads</li>
              <li>Most Popular Choice</li>
            </ul>
            <button onClick={() => handleSubscribe('residential-pro')} className="pricing-cta featured">
              {isAuthenticated ? 'Purchase Now' : 'Get Started'}
            </button>
          </div>

          <div className="pricing-card">
            <div className="pricing-header">
              <h3>100GB+</h3>
              <div className="price">
                <span className="amount">$1.30</span>
                <span className="period">/GB</span>
              </div>
            </div>
            <ul className="pricing-features">
              <li>Rotating IPs</li>
              <li>Priority Support</li>
              <li>Unlimited Threads</li>
              <li>Best Value</li>
            </ul>
            <button onClick={() => handleSubscribe('residential-enterprise')} className="pricing-cta">
              {isAuthenticated ? 'Purchase Now' : 'Get Started'}
            </button>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="pricing-faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-grid">
          <div className="faq-item">
            <h3>What payment methods do you accept?</h3>
            <p>We accept all major cryptocurrencies.</p>
          </div>
          <div className="faq-item">
            <h3>Can I upgrade my plan later?</h3>
            <p>Yes, you can upgrade or downgrade your plan at any time.</p>
          </div>
          <div className="faq-item">
            <h3>Do you offer refunds?</h3>
            <p>No, we do not offer refunds at this moment.</p>
          </div>
        </div>
      </section>

      <footer className="landing-footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Products</h3>
            <ul>
              <li><Link to="/lte-proxies">LTE Proxies</Link></li>
              <li><Link to="/residential-proxies">Residential Proxies</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Company</h3>
            <ul>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Support</h3>
            <ul>
              <li><Link to="/faq">FAQ</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} DeathBy.Design. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default PricingPage; 