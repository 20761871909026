// frontend/src/components/Header.js
import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { User, Settings, Menu } from 'react-feather';
import '../styles/Header.css';

const Header = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  const handleLogout = async () => {
    await logout();
    navigate('/');
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo">
          DeathBy.Design
        </Link>
        
        <nav className="nav-links">
          <Link to="/pricing">Pricing</Link>
          {isAuthenticated ? (
            <>
              <Link to="/dashboard">Dashboard</Link>
              <div className="nav-actions">
                <Link to="/profile" className="icon-button">
                  <User size={20} />
                </Link>
                <Link to="/settings" className="icon-button">
                  <Settings size={20} />
                </Link>
                <button onClick={handleLogout} className="auth-button logout">
                  Logout
                </button>
              </div>
            </>
          ) : (
            <div className="nav-actions">
              <Link to="/login" className="auth-button login">
                Sign In
              </Link>
              <Link to="/register" className="auth-button signup">
                Sign Up
              </Link>
            </div>
          )}
        </nav>

        <div className="mobile-menu" ref={menuRef}>
          <button className="menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Menu size={24} />
          </button>
          
          <div className={`dropdown-menu ${isMenuOpen ? 'active' : ''}`}>
            <Link to="/pricing" onClick={() => setIsMenuOpen(false)}>Pricing</Link>
            {isAuthenticated ? (
              <>
                <Link to="/dashboard" onClick={() => setIsMenuOpen(false)}>Dashboard</Link>
                <Link to="/profile" className="icon-button" onClick={() => setIsMenuOpen(false)}>
                  <User size={20} /> Profile
                </Link>
                <Link to="/settings" className="icon-button" onClick={() => setIsMenuOpen(false)}>
                  <Settings size={20} /> Settings
                </Link>
                <button onClick={handleLogout} className="auth-button logout">
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="auth-button login" onClick={() => setIsMenuOpen(false)}>
                  Sign In
                </Link>
                <Link to="/register" className="auth-button signup" onClick={() => setIsMenuOpen(false)}>
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;